// Accordion
.toggle {
    .title-name {
        background: $primary;
    }

    .toggle-title {
        position: relative;
        display: block;
        margin-bottom: 1em;

        h3 {
            margin: 0px;
            cursor: pointer;
        }
        &.active h3 {
        }
    }

    .toggle-inner {
        text-align: left;
        margin: 2em auto 1em;
        padding: 0 1em 1em 1em;
        display: none;

        div {
            max-width: 100%;
        }
    }

    .toggle-title {
        .title-name {
            display: block;
            text-align: left;
            color: $white;
            font-size: 1.125rem;
            padding: 1em 3em 1em 1em;

            &:after {
                position: absolute;
                content: "\2303";
                width: 24px;
                height: 24px;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;
                right: 1em;
                top: 40%;
                transform: translateY(-50%) rotate(180deg);
                text-align: center;
            }
        }
    }

    &.active .title-name:after {
        content: "\2303";
        top: 55%;
        transform: translateY(-50%);
    }
}
