// Colours
$white: #ffffff;
$black: #000000;
$light-grey: #d9d9d9;
$dark-grey: #212121;

$primary: #6e645f;
$secondary: #dbd4ce;

$positive: #0ab04b;
$negative: #e64747;

$label: #1c4c56;
$input-bg: #ffffff;
$input-border: #dfdfdf;
$input-font: #212121;

// Spacing
$btn-padding: 0.75em 1em;

// Typography
$heading: playfair-display, serif;
$font: "DM Sans", sans-serif;
