@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints.scss";

@import "partials/_cart.scss";

//Font
@import url("https://use.typekit.net/xvb6osj.css");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

$max-width: 1440px;
$min-width: 320px;

// Mouse Pointer Overwrite

body #magicMouseCursor {
    border: 1px solid $primary !important;
}

body #magicPointer {
    background: $primary !important;
}

body .magic-hover {
    padding: 0.5em;
}

// aos

// Global Styles
*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    background: $secondary;
    font-family: $font;
    position: relative;
    text-align: center;
    -webkit-overflow-scrolling: touch;

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

h1 {
    font-family: $heading;
    font-weight: 600;
}

p {
    font-size: 1.125rem;
    line-height: 30px;
    color: $primary;
}

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

.container {
    padding: 20px 40px;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;

    @include md {
        padding: 20px;
    }

    .row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 auto;
    }
}

nav {
    &.breadcrumbs {
        text-align: left;

        ul {
            display: inline-block;

            li {
                display: inline-block;

                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}

body>header {
    nav {
        ul {
            li {
                list-style: none;
                display: inline-block;
            }
        }

        // &.user {
        //     .cart {
        //         box-shadow: 5px 5px 5px rgba($off-black, 0.2);
        //         .cart-items {
        //             padding-top: 10px!important;
        //             padding-bottom: 0!important;
        //             .cart-item {
        //                 padding: 0 0;
        //                 .item-details {
        //                     flex: 1 1 auto;
        //                 }
        //                 .item-qty {
        //                     flex: 0 0 auto;
        //                 }
        //                 .item-price {
        //                     flex: 1 1 auto;
        //                     text-align: right;
        //                 }
        //                 .item-remove {
        //                     flex: 0 0 auto;
        //                     margin-right: -10px;
        //                     margin-left: 10px;
        //                 }
        //             }
        //         }
        //         .totals {
        //             font-weight: 600;
        //             padding:  5px 10px 5px 5px;
        //             border-top: 1px dotted $off-black;
        //         }
        //     }
        // }
    }
}

main {

    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;

            li {
                display: block;
                padding: 0 10px;

                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }

                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }

        form {
            max-width: 600px;
            flex: 1 0 auto;
        }

        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }

    &.checkout {
        &.cart {
            .cart-items {
                &>li.cart-item {
                    padding: 5px 10px;

                    input {
                        margin: 0;
                    }

                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }

                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }

                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);

                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }

                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }

                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .totals {
                font-weight: 600;
                padding: 0 20px;

                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }

        &.shipping,
        &.payment {
            &>.container>.row {
                justify-content: center;
                align-items: flex-start;
            }

            form {
                margin: 0;

                fieldset {
                    &.shipping-methods {
                        padding: 20px;

                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;

                            input {
                                margin-right: 1em;
                            }

                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }

                    &.buttons {
                        display: flex;
                        justify-content: center;

                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }

                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }

                .message {
                    padding: 20px 10px;
                    text-align: left;

                    ul {
                        margin: 1em 2em;
                    }
                }
            }

            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;

                ul {
                    margin: 1em 0;

                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;

                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;

            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;

                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }

                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }

        &.single {}
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// -- Miss Gertrude

// -- Header
header {
    .desktop-menu {
        width: 100%;

        .row {
            @extend .container;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include md {
                flex-direction: row;
            }

            @include xs {
                padding: 1rem 20px;
            }
        }

        .column.center {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
        }

        .column.left {
            flex: 0 0 40%;
            display: flex;
            justify-content: flex-start;

            nav ul {
                li {
                    margin: 0 1.25em;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        font-size: 1.125rem;
                        text-decoration: none;
                        color: $primary;
                    }
                }
            }
        }

        .column.right {
            flex: 0 0 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .phone {
                color: $primary;
                text-decoration: none;

                &::before {
                    content: "";
                    display: inline-block;
                    margin-right: 0.5rem;
                    width: 24px;
                    height: 24px;
                    vertical-align: middle;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-image: url("/img/phone-logo.svg");
                }
            }

            ul.social-media {
                display: flex;
                list-style-type: none;
                margin: 0 2em;

                li {
                    margin: 0 0.15em;
                    transition: all 100ms ease-in-out;
                    border-radius: 100%;

                    &:hover {
                        transform: scale(1.125);
                    }

                    a {
                        img {
                            width: 28px;
                            height: 28px;
                        }
                    }
                }
            }
        }

        .logo {
            display: block;
            width: auto;

            @include md {
                height: 65px;
            }

            @include xs {
                height: 55px;
            }
        }

        .header-nav {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include md {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            a {
                text-decoration: none;
            }

            ul {
                background-color: transparent;
                list-style: none;
                margin: 0;
                padding-left: 0;
                display: flex;

                @include md {
                    width: 100%;
                    flex-direction: column;
                    align-items: center;
                    margin: 1em auto;
                }

                li {
                    color: $dark-grey;
                    background: transparent;
                    display: block;
                    padding: 1em;
                    text-decoration: none;
                    transition-duration: 300ms;
                    margin: 0;

                    &:hover {
                        a {
                            color: $primary;
                        }
                    }

                    &.li-overlay {
                        margin-right: 1em;

                        &.small-pos {
                            position: relative;

                            &:hover {
                                a {
                                    color: $white;
                                }
                            }
                        }

                        &:hover {
                            background: $primary;
                            z-index: 4;

                            .categories-menu {
                                color: $white;

                                &:after {
                                    content: "";
                                    display: inline-block;
                                    margin-left: 0.5rem;
                                    width: 24px;
                                    height: 16px;
                                    vertical-align: middle;
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    background-position: center;
                                    background-image: url("/img/up-arrow.svg");
                                }
                            }
                        }
                    }

                    a {
                        color: $dark-grey;
                        font-size: 1.125rem;
                        font-weight: 500;

                        @include md {
                            color: $white;
                        }
                    }

                    >ul {
                        background: white;
                        visibility: hidden;
                        opacity: 0;
                        min-width: 250px;
                        position: absolute;
                        transition: all 0.5s ease;
                        margin-top: 0.15rem;
                        left: 1em;
                        top: 3em;
                        padding: 1em 1em;
                        display: none;
                        z-index: 200;
                        z-index: 100;
                        transition: all 300ms ease;

                        &.dropdown.small {
                            width: fit-content;
                            height: fit-content;
                            padding: 1em 1em 0 1em;

                            li {
                                padding: 0 0 1em 0;
                            }
                        }

                        a {
                            color: $black !important;
                            font-weight: 400;
                            display: block;
                            text-align: left;
                            width: 100%;

                            &:hover {
                                color: $primary !important;
                            }
                        }

                        >li {
                            padding: 0 1em 1em 0;
                            position: relative;
                            margin: 0;
                            width: fit-content;

                            &:hover {
                                background-color: transparent !important;
                            }

                            >ul {
                                background: white;
                                visibility: hidden;
                                opacity: 0;
                                min-width: 5rem;
                                position: absolute;
                                left: 100%;
                                top: -0.15rem;
                                display: none;

                                &:hover {
                                    visibility: visible;
                                    opacity: 1;
                                    display: flex;
                                    flex-direction: column;
                                    flex-wrap: wrap;
                                    align-content: flex-start;
                                }
                            }
                        }

                        &:hover {
                            visibility: visible;
                            opacity: 1;
                            display: flex;
                        }
                    }
                }
            }

            ul li:hover>ul,
            ul li ul:hover {
                visibility: visible;
                opacity: 1;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: flex-start;
            }
        }
    }
}

// -- Mobile menu

.desktop-menu {
    display: block;

    @include lg {
        display: none;
    }
}

.mobile-menu {
    display: none;
    @extend .container;

    @include lg {
        position: relative;
        display: block;
        z-index: 101;
        border-bottom: 1px solid $primary;
    }

    .logo {
        width: 9em;
    }

    .mobile-menu-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5em;

        .column.left {
            .phone {
                color: $primary;
                text-decoration: none;

                &::before {
                    content: "";
                    display: inline-block;
                    margin-right: 0.5rem;
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-image: url("/img/phone-logo.svg");
                }
            }
        }

        .column.right {
            display: flex;
            flex-direction: row;

            ul.social-media {
                display: flex;
                list-style-type: none;
                margin: 0;

                li {
                    margin: 0 0.15em;
                    transition: all 100ms ease-in-out;
                    border-radius: 100%;

                    &:hover {
                        transform: scale(1.125);
                    }

                    a {
                        img {
                            width: 28px;
                            height: 28px;
                        }
                    }
                }
            }
        }
    }
}

.burger-menu {
    display: none;

    @include lg {
        width: 30px;
        height: 30px;
        display: block;
        margin-left: 1.5em;
        padding: 0;
        border-bottom: 0 none;
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 101;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                background: $primary;
                transform: translateY(11px) translateX(0) rotate(45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                background: $primary;
                transform: translateY(-9px) translateX(0) rotate(-45deg);
            }
        }

        span {
            background: $primary;
            border: none;
            height: 2px;
            width: 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 14px;
            }

            &:nth-of-type(3) {
                top: 24px;
            }
        }
    }
}

.overlay {
    position: fixed;
    background: $secondary;
    top: 190.8px;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    // overflow: hidden;
    z-index: 100;

    &.open {
        opacity: 0.98;
        visibility: visible;
        height: 100%;

        li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.35s;

            &:nth-of-type(2) {
                animation-delay: 0.4s;
            }

            &:nth-of-type(3) {
                animation-delay: 0.45s;
            }

            &:nth-of-type(4) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(5) {
                animation-delay: 0.55s;
            }

            &:nth-of-type(6) {
                animation-delay: 0.6s;
            }

            &:nth-of-type(7) {
                animation-delay: 0.65s;
            }

            &:nth-of-type(8) {
                animation-delay: 0.7s;
            }

            &:nth-of-type(9) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(10) {
                animation-delay: 0.8s;
            }

            &:nth-of-type(11) {
                animation-delay: 0.85s;
            }

            &:nth-of-type(12) {
                animation-delay: 0.9s;
            }
        }
    }

    @include md {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay-menu {
    display: block;

    nav {
        position: absolute;
        top: 2em;
        left: 50%;
        transform: translate(-50%, 2em);
        width: 100%;

        ul {
            display: flex;
            flex-direction: column;
            width: 100%;

            li {
                padding: 1.5em 1em;

                a {
                    color: $primary;
                    font-family: $heading;
                    font-size: 2rem;
                    text-decoration: none;
                }
            }
        }
    }
}

// -- Footer

footer {
    border-top: 1px solid $primary;
    padding-top: 1em;
    margin-top: 4em;

    .top-footer {
        margin-bottom: 1em;

        .row {
            @include xs {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .column.left {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            @include xs {
                align-items: center;
            }

            .logo {
                height: 100px;

                @include xs {
                    height: 90px;
                }
            }

            p {
                margin: 0.75em 0;

                @include xs {
                    font-size: 1rem;
                    margin: 0.25em 0 0;
                }
            }

            .phone-socmed {
                display: flex;
                align-items: center;

                .phone {
                    font-size: 1.125rem;
                    color: $primary;
                    text-decoration: none;

                    &::before {
                        content: "";
                        display: inline-block;
                        margin-right: 0.5rem;
                        width: 24px;
                        height: 24px;
                        vertical-align: middle;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        background-image: url("/img/phone-logo.svg");
                    }

                    @include xs {
                        font-size: 1rem;
                    }
                }

                ul.social-media {
                    display: flex;
                    list-style-type: none;
                    margin: 0 2em;

                    li {
                        margin: 0 0.15em;
                        transition: all 100ms ease-in-out;
                        border-radius: 100%;

                        &:hover {
                            transform: scale(1.125);
                        }

                        a img {
                            width: 28px;
                            height: 28px;
                        }
                    }
                }
            }
        }

        .column.right.opening-hours {
            text-align: left;
            display: flex;
            align-content: flex-start;
            flex-direction: column;
            justify-content: space-between;

            @include xs {
                margin-top: 1em;
                max-width: 100%;
            }

            .opening-hours-item {
                display: flex;
                justify-content: space-between;
                color: $primary;

                @include xs {
                    margin-bottom: 0.5em;
                }

                .day {
                    margin-right: 1em;

                    @include xs {
                        margin-right: 3em;
                    }
                }
            }
        }
    }

    .bottom-footer {
        background: $primary;

        .column.left {
            ul li {
                display: inline-block;
                margin-right: 1em;

                a {
                    font-size: 0.85rem;
                    text-decoration: none;
                    color: $white;
                }
            }
        }

        .column.right {
            .credit-link {
                font-size: 0.85rem;
                color: $white;
            }
        }
    }
}

// -- Home

#home-main-text {
    margin: 8em auto 10em;

    @include md {
        margin: 3em auto 0;
    }

    h1 {
        color: $primary;
        margin: 0 0 0.65em;
        font-size: 5em;
        letter-spacing: 4px;
        line-height: 50px;
        text-transform: uppercase;

        @include xs2 {
            font-size: 3.5rem;
            letter-spacing: 3px;
            line-height: 62px;
        }
    }

    .home-main-text-content {
        display: block;
        max-width: 750px;
        margin: 0 auto;

        p {
            font-size: 1.45rem;
            line-height: 42px;

            @include xs {
                font-size: 1.25rem;
                line-height: 35px;
            }
        }
    }

    .button.border {
        display: block;
        width: fit-content;
        padding: 0.75em 2em;
        margin: 1.5em auto 0;
        font-size: 1.125rem;
        border-radius: 8px;

        @include xs {
            padding: 0.75em 1.5em;
        }
    }
}

#home-exp-text {
    margin: 0 auto 6em;

    @include md {
        margin: 0 auto 2em;
    }

    .row {
        @include md {
            flex-direction: column;
        }
    }

    .text {
        h1 {
            color: $primary;
            font-size: 2.25em;
            text-align: left;
            margin: 0;
            letter-spacing: 2px;

            @include lg2 {
                font-size: 2em;
            }

            @include xs2 {
                font-size: 1.5em;
            }

            .shadow-h1 {
                font-size: 5em;
                text-shadow: 0px 2px 0px $primary,
                    0px 14px 10px rgba(0, 0, 0, 0.15),
                    0px 24px 2px rgba(0, 0, 0, 0.1),
                    0px 34px 30px rgba(0, 0, 0, 0.1);

                @include lg2 {
                    font-size: 4.5em;
                }

                @include xs2 {
                    font-size: 3.5em;
                }
            }
        }

        p {
            margin: 0.5em 8em 2em 8em;
            text-align: left;
            font-size: 1.45rem;
            line-height: 40px;

            @include lg {
                margin: 0.5em 2em 2em 0;
            }

            @include xs {
                margin: 1em auto 2em;
                font-size: 1.25rem;
                line-height: 35px;
            }
        }
    }

    .column.right {
        @include lg {
            width: 500px;
        }

        @include md {
            width: 100%;
        }

        img {
            @include xs {
                width: 100%;
            }
        }
    }
}

#home-services-link {
    position: relative;
    height: 400px;

    .left-aes-img {
        position: absolute;
        left: 0;
        top: -12em;

        @include lg {
            left: 0;
            top: 15em;
            width: 375px;
        }

        @include md {
            bottom: -15em;
            width: 50%;
            top: unset;
        }

        @include xs {
            bottom: -5em;
            width: 60%;
            top: unset;
        }
    }

    .meet-our-team-aes {
        display: block;
        font-family: $heading;
        text-align: center;
        letter-spacing: 4.75px;
        text-transform: uppercase;
        color: $primary;
        transform: rotate(90deg);
        position: absolute;
        right: -3em;
        top: 50%;
        font-size: 1.75em;
        line-height: 40px;

        @include md {
            display: none;
        }
    }

    .services-link {
        margin: 0 auto;
        display: block;
        width: 400px;

        @include xs {
            width: 100%;
        }

        p.text-w-line {
            display: flex;
            white-space: nowrap;
            align-items: center;
            font-size: 1.5rem;
            text-align: left;

            @include md {
                margin: 0 1em;
            }

            &:after {
                content: "";
                width: 100%;
                margin-left: 1em;
                border-top: 1px solid $primary;
            }
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        h1 {
            text-align: center;
            margin: 0.25em auto;
            font-size: 2.85em;
            color: $primary;

            @include md {
                font-size: 2rem;
                margin: 0.5em auto 0;
            }
        }
    }
}

#home-team {
    @include lg {
        margin-top: 20em;
    }

    @include md {
        margin-top: 16em;
    }

    @include xs {
        margin-top: 8em;
    }

    h1 {
        text-align: left;
        text-transform: uppercase;
        font-size: 4.5rem;
        margin: 0;
        color: rgba(110, 100, 95, 0.7);

        @include xs2 {
            font-size: 3.5rem;
        }

        @include xs {
            text-align: center;
            font-size: 3rem;
            margin-bottom: 1.5em;
        }
    }

    .home-team-mobile-span {
        display: none;

        @include xs {
            display: block;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }

    .home-team-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3em;
        margin: 3em auto;

        @include lg2 {
            grid-template-columns: repeat(2, 1fr);
        }

        @include xs {
            display: none;
        }

        &.home-team-mobile-slider {
            display: none;

            @include xs {
                display: block;
            }

            .slick-slide {
                height: 100%;

                &:before {
                    background-color: transparent !important;
                }
            }

            .slick-prev,
            .slick-next {
                top: -50px;
                transform: unset;
            }

            .slick-prev {
                left: 40%;

                &:before {
                    content: "";
                    background-image: url("/img/arrow-left.svg");
                    background-size: 30px 30px;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    z-index: 2;
                }
            }

            .slick-next {
                right: 40%;

                &:before {
                    content: "";
                    background-image: url("/img/arrow-right.svg");
                    background-size: 30px 30px;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    z-index: 2;
                }
            }

            .home-team-desc {
                margin-top: 0.5em;
            }
        }

        .home-team-item {
            a {
                color: $primary;
                text-decoration: none;
            }

            img {
                width: 100%;
            }
        }

        .home-team-desc {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .home-team-name {
                display: flex;
                align-items: center;
                flex: auto;
                font-size: 2rem;
                font-family: $heading;

                &:after {
                    content: "";
                    margin: 0 1em;
                    border-top: 1px solid $primary;
                    width: 100%;
                }
            }

            .home-team-pos {
                white-space: nowrap;
            }
        }
    }
}

#home-ig-feed {
    .home-ig-username {
        display: flex;
        align-items: center;
        text-decoration: none;

        p {
            margin-left: 1em;
            font-size: 1.25rem;
        }
    }
}

//-- Instafeed

#instafeed {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0rem;
    margin-top: 2em;
}

#instafeed a {
    display: block;
    position: relative;
    border: 1px solid $white;
    max-height: 250px;

    @include md {
        max-height: 150px;
    }

    @include xs {
        max-height: 90px;
    }
}

#instafeed img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#instafeed .rollover {
    background: rgba(0, 0, 0, 0.4);
    font-size: 28px;
    position: absolute;
    color: #ffffff;
    transition: 0.5s ease;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    text-align: center;
    line-height: 300px;
}

#instafeed a.insta-tag:hover .rollover {
    opacity: 2;
}

// -- CTA

#cta-button {
    display: flex;
    align-items: center;
    flex: auto;

    &:before {
        content: "";
        margin-right: 2em;
        border-top: 1px solid $primary;
        width: 100%;
    }

    .round-button {
        position: relative;
        color: inherit;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        border: 1px solid $primary;
        padding: 2em;
        border-radius: 100%;
        min-width: 250px;
        min-height: 250px;
        background: linear-gradient(to right, $primary 50%, $secondary 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        transition: all 1s ease;

        &:hover {
            background-position: left bottom;

            p,
            h1 {
                color: $white;
            }
        }

        @include md {
            min-width: 200px;
            min-height: 200px;
        }

        @include xs {
            padding: 1em;
            min-width: 180px;
            min-height: 180px;
        }

        h1 {
            font-size: 2.25rem;
            color: $primary;
            margin: 0.15em 0 0;

            @include md {
                font-size: 2rem;
            }
        }
    }
}

// -- CMS Page

#cms-page-intro {
    margin-top: 2em;

    @include xs {
        margin-top: 1em;
    }

    .cms-page-text-intro {
        max-width: 900px;

        h1 {
            color: $primary;
            text-align: left;
            font-size: 4.5rem;
            margin: 0 0 0.5em;

            @include md {
                font-size: 3.5rem;
            }

            @include xs {
                font-size: 3rem;
            }
        }

        p {
            text-align: left;
        }
    }

    .cms-page-img-intro {
        display: block;
        margin: 2em auto 0;

        img {
            width: 100%;
            height: 600px;
            object-fit: cover;

            @include xs {
                height: 300px;
            }
        }
    }
}

#cms-page-content {
    .text {
        text-align: left;
    }
}

// -- About Page

#about-team {
    h1 {
        color: $primary;
        text-align: left;
        font-size: 3rem;
        line-height: 30px;
        text-transform: uppercase;
        padding-bottom: 0.5em;

        @include xs {
            font-size: 2.5rem;
            line-height: 20px;
        }
    }

    .about-team-grid {
        .about-team-item {
            display: flex;
            border-top: 1px solid $primary;
            padding: 3em 0;

            @include xs {
                padding: 2em 0;
                flex-direction: column !important;
            }

            &:nth-child(odd) {
                flex-direction: row;

                .about-team-desc {
                    margin-left: 5em;

                    @include lg {
                        margin-left: 3em;
                    }

                    @include xs {
                        margin: 1em 0 0;
                    }
                }
            }

            &:nth-child(even) {
                flex-direction: row-reverse;

                .about-team-desc {
                    margin-right: 5em;

                    @include lg {
                        margin-right: 3em;
                    }

                    @include xs {
                        margin: 1em 0 0;
                    }
                }
            }

            img {
                width: 100%;
                max-width: 600px;
                height: 400px;
                object-fit: cover;

                @include lg {
                    max-width: 400px;
                    height: 300px;
                }

                @include xs {
                    height: 250px;
                    max-height: 100%;
                }
            }

            .about-team-desc {
                text-align: left;
                width: calc(100% - 500px);

                @include lg {
                    width: calc(100% - 400px);
                }

                @include xs {
                    width: 100%;
                }

                .about-team-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .about-team-name {
                        display: flex;
                        align-items: center;
                        flex: auto;
                        font-size: 2rem;
                        font-family: $heading;

                        &:after {
                            content: "";
                            margin: 0 1em;
                            border-top: 1px solid $primary;
                            width: 100%;
                        }
                    }

                    .about-team-pos {
                        white-space: nowrap;
                    }
                }

                .about-team-content {
                    margin-top: 2em;
                }
            }
        }
    }
}

// -- Services & Pricing Page

#services-pricing-content {
    .services-quick-links {
        display: flex;
        align-items: center;
        text-align: left;

        li {
            list-style-type: none;
            margin-right: 2em;

            &:not(:first-child) {
                border-left: 1px solid $primary;
                padding: 0 2em;
                margin-right: 0;
            }

            a {
                color: $primary;
                font-size: 1.75rem;
                font-weight: 600;
                font-family: $heading;
                letter-spacing: 1px;
                text-transform: uppercase;
                text-decoration: none;

                @include xs {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .services-pricing-item-position {
        display: flex;
        margin: 3em 0 0;

        &:first-of-type {
            justify-content: flex-end;

            @include lg {
                justify-content: flex-start;
            }
        }

        .services-pricing-item {
            width: 680px;

            h3 {
                color: $primary;
                font-size: 3rem;
                font-family: $heading;
                letter-spacing: 1px;
                text-transform: uppercase;
                text-align: left;

                @include xs {
                    font-size: 2.5rem;
                }
            }

            .prompt {
                &:first-of-type {
                    margin-top: 1.5em;
                }

                text-align: left;
                margin-top: 0.5em;
            }

            .small-text {
                display: block;
                color: $primary;

                &.left {
                    text-align: left;
                }

                &.right {
                    text-align: right;
                }
            }

            .services-pricing-item-row {
                display: flex;
                justify-content: space-between;
                margin-top: 1em;

                &:first-of-type {
                    border-top: 1px solid $primary;
                    margin-top: 1.5em;
                    padding-top: 1.5em;
                }

                &.sub-section {
                    margin: 2em 0 1em;
                }

                .col-1 {
                    text-align: left;
                }

                .col-1.bold {
                    text-transform: uppercase;
                    font-weight: 700;
                }

                .col-2 {
                    white-space: nowrap;
                }
            }

            .services-pricing-item-no-row {
                text-align: left;
                margin: 2em 0;

                .col-1.bold {
                    text-transform: uppercase;
                    font-weight: 700;
                }

                &:first-of-type {
                    border-top: 1px solid $primary;
                    margin-top: 1.5em;
                    padding-top: 1.5em;
                }
            }
        }
    }
}


// -- Modal

/* The Modal (background) */

.overlay-modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
}

.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    padding-top: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.75);

    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &.booking {
        background-color: transparent;
    }

    .modal-content {
        margin: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        background-color: $secondary;
        color: $white;
        border-radius: 5px;
        overflow: auto;
        padding: 4em 2em 3em;
        margin-bottom: 1.5em;
        margin-right: 1.5em;

        @include md {
            width: 100%;
            max-height: 100%;
            margin: 0;
        }

        .booking-name {
            color: $black;
            font-weight: bold;
            padding-top: 1.5rem;
        }

        img {
            object-fit: cover;
            display: block;
            position: relative;
            margin-top: 2em;
            height: 200px;
            width: 200px;
        }

        .iframe-wrapper {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .prompt-text {
        h3 {
            color: $primary;
            font-size: 1.5rem;
            margin-bottom: 0.5em;
        }
    }

    .button.border {
        margin: 1em auto 0;
        padding: 0.75em 1.5em;
    }

    .close-booking {
        color: $primary;
        display: block;
        float: none;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        padding: 0px 16px;
        font-size: 3em;

        @include xs {
            font-size: 2rem;
        }

        &:hover,
        :focus {
            color: $negative;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.booking-modal {
    :hover {
        cursor: pointer;
    }
}
